
import Vue from "vue";
import * as types from "@/store/mutation-types";
import { FormBlock } from "@/components/FormBuilder/types";
import { objectDifference } from "@/helpers";
import {
  atlasPlatformMapActions,
  atlasPlatformMapGetters,
  atlasPlatformMapMutations,
  atlasPlatformMapState
} from "@/store/modules/atlasPlatform";
import { atlasPlatformFormSchema } from "@/forms/admin/atlasPlatform";

export default Vue.extend({
  name: "UpdateAtlasPlatformView",
  data() {
    return {
      resourceNotFound: false,
      errorMessage: "",
      isLoading: false,
      successMessage: "",
      loadingText: ""
    };
  },
  created() {
    this.getAtlasPlatform(this.platformId)
      .then(data => {
        this.SET_EDIT(data);
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      });
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  methods: {
    ...atlasPlatformMapActions(["getAtlasPlatform", "updateAtlasPlatform"]),
    ...atlasPlatformMapMutations(["SET_EDIT"]),
    ...atlasPlatformMapMutations({ editField: types.SET_EDIT_FIELD }),
    formFieldChangeHandler({ key, value }: { key: any; value: any }) {
      this.editField({ key, value });
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "cancelEditAtlasPlatform":
          this.atlasPlatformCallback();
          break;
        case "save":
          this.updateCallback();
          break;
      }
    },
    async atlasPlatformCallback(): Promise<any> {
      this.$router.push("/admin/atlasPlatforms").catch(() => {});
    },
    async deleteOneAccreditedBuilder(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting atlas platform. Please wait...";
        this.$appNotifySuccess("Atlas Platform Successfully Deleted");
        this.atlasPlatformCallback();
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async updateCallback(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Updating atlas platform. Please wait...";
        await this.updateAtlasPlatform({
          id: this.platformId,
          update: this.updatedFields
        });
        this.$appNotifySuccess("Atlas Platform Updated Successfully");
        this.atlasPlatformCallback();
      } catch (err) {
        this.errorMessage = "Operation specified is not supported";
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    }
  },
  computed: {
    ...atlasPlatformMapGetters([
      "getAtlasPlatformById",
      "editingAtlasPlatform"
    ]),
    ...atlasPlatformMapState(["makingApiRequest", "editing"]),
    platformId(): string {
      return this.$route.params.atlasPlatformId;
    },
    atlasPlatform(): any {
      return this.getAtlasPlatformById(this.platformId);
    },
    updatedFields(): any {
      const edited = this.editingAtlasPlatform;
      const original = this.atlasPlatform;
      return edited && original ? objectDifference(edited, original) : {};
    },
    accreditedBuilderFields(): FormBlock[] {
      return atlasPlatformFormSchema;
    }
  }
});

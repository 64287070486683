import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useSelectField
} from "@/components/FormBuilder/Helpers";

export const atlasPlatforms = [
  "Quote",
  "Policy Inquiry",
  "Billing Inquiry",
  "Change Request",
  "Claims",
  "Agent Application",
  "Personal Liability Application",
  "Mortgages",
  "Quick pay",
  "Agencies",
  "Companies"
];

const atlasPlatformsOptions = atlasPlatforms.sort().map(platform => {
  return {
    label: platform,
    value: platform
  };
});
export const atlasPlatformFormSchema: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup([
      useSelectField({
        key: "name",
        label: "Platform Name",
        required: true,
        options: atlasPlatformsOptions,
        filterable: true,
        allowCreate: true
      })
    ])
  ])
];
